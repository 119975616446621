.App {
  text-align: center;
}

*{
  font-family: Avenir, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow-y:hidden;
}

.titleCard{
  position: absolute;
  top:10px;
  left:10px;
  width:20vw;
}
.linkedInButton{
  position: absolute;
  bottom:10px;
  right:10px;
  height:30px;
  cursor:pointer;
}

.titleCardImg{
  width:20vw;
}

.promptExplore{
  color:white;
  padding-top: 10px;
  clear:both;
}

.bottomCredits{
  position:fixed;
  bottom:5px;
  padding:0 20px;
  align-items: center;
  font-size: 8px;
  color: whitesmoke;
  font-family: Avenir, sans-serif;
  text-align: center;
}

.rightArrowHidden{
  transform:scale(0);
  opacity: 0;
  pointer-events: none;
}

.rightArrow{
  opacity:1;
  width: 25px;
  height:25px;
  cursor:pointer;
  position:fixed;
  right:calc(43vw - 25vh);
  transition:.5s;
  box-shadow: darkslategray;
  z-index: 100;
}

.rightArrow:hover{
  transform:scale(1.2);
}

.recordBin{
  position:absolute;
  top:0;
  left:0;
  overflow-y:auto;
  width:100vw;
  height:100vh;
}

.hidden{
  display:none;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
