.gritCover{
    width:100%;
    height:100%;
    opacity: .37;
    z-index: inherit;
    position: absolute;
    left:0;
}

.recordImage{
    width:100%;
    height:100%;
    z-index: inherit;
    position: absolute;
    left:0;
}

.recordContainer{
    width:50vh;
    height:50vh;
    position:absolute;
    perspective: 500px;
    transform-style: preserve-3d;
    transition:.5s;
    transform-origin: bottom;
    top: 10px;
}

.centerRecord{
    margin-left:calc((100vw - 50vh) / 2);
}

.gritCover:hover{
    cursor:pointer;
    opacity: .3;
}

.recordTipped{
    transform: perspective(10000px) rotateX(-80deg);
}