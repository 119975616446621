.mobileModal{
    position: absolute;
    z-index: 1000;
    width:100vw;
    height:100vh;
    border-radius: 20px;
    background-color: #282c34;
    text-align: left;
}
@media only screen and (min-width: 700px) {
    .mobileModal {
        visibility: hidden;
    }
}

.mobileModal h1,
.mobileModal h3
{
    color:white;
    padding:30px;

}

.circularPhoto{
    height:100px;
    width: 100px;
    border-radius: 50px;
    margin:30px 0 0 30px;
}